<template>
  <v-card outlined flat>
    <v-list-item class="mt-2">
      노출 날짜
      <v-menu v-model="dateMenu" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            prepend-inner-icon="mdi-calendar"
            style="max-width: 290px"
            v-bind="attrs"
            outlined
            hide-details
            readonly
            dense
            clearable
            placeholder="노출일"
            class="mx-3"
            v-on="on"
          />
        </template>
        <v-date-picker v-model="date" @input="dateMenu = false" />
      </v-menu>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>작성 멘토</v-list-item-title>
        <MentorInput v-model="mentor" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>편지 내용</v-list-item-title>
        <MarkdownEditor v-model="input.content" height="400px" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-spacer />
      <v-btn color="#ffd600" class="mb-4" @click="updateLetterOfMentor">{{ $route.params.uid ? '수정' : '추가' }}하기</v-btn>
    </v-list-item>
  </v-card>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, toRefs } from '@vue/composition-api'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import MentorInput from '@/components/input/Mentor.vue'

export default defineComponent({
  components: {
    MarkdownEditor,
    MentorInput,
  },

  setup(props, { root }) {
    const state = reactive({
      dateMenu: false,
      date: null,
      mentor: null,
      input: {
        content: null,
        publishedAt: null,
      },
    })

    const getListLetterOfMentor = async () => {
      if (!root.$route.params.uid) return

      const listLetterOfMentor = await root.$store.dispatch('letterOfMentor/getListLetterOfMentor', {
        offset: 10000,
      })
      for (const e of listLetterOfMentor.data) {
        if (root.$route.params.uid == e.id) {
          if (e.publishedAt) {
            const date = new Date(e.publishedAt)
            const Y = String(date.getFullYear())
            const M = String(date.getMonth() + 1).padStart(2, '0')
            const D = String(date.getDate()).padStart(2, '0')
            state.date = `${Y}-${M}-${D}`
          }
          state.mentor = e.Mentor
          state.input.publishedAt = e.publishedAt
          state.input.content = e.content
        }
      }
    }

    const updateLetterOfMentor = async () => {
      try {
        if (state.date) state.input.publishedAt = new Date(state.date)
        if (root.$route.params.uid) {
          await root.$store.dispatch('letterOfMentor/updateLetterOfMentor', {
            input: {
              id: Number(root.$route.params.uid),
              publishedAt: state.input.publishedAt,
              mentorId: Number(state.mentor.id),
              content: state.input.content,
            },
          })

          alert('수정 완료!')
          root.$router.push('/daily_letter')
        } else {
          await root.$store.dispatch('letterOfMentor/createLetterOfMentor', {
            input: {
              publishedAt: state.input.publishedAt,
              mentorId: Number(state.mentor.id),
              content: state.input.content,
            },
          })

          alert('추가 완료!')
          root.$router.push('/daily_letter')
        }
      } catch (err) {
        console.log(err)
        alert(err)
      }
    }

    onBeforeMount(async () => {
      await getListLetterOfMentor()
    })

    return {
      ...toRefs(state),
      updateLetterOfMentor,
    }
  },
})
</script>
